import React from "react";
import "./../styles/index.css"
class ErrorPage extends React.Component {
  render() {
    return (
      <div className="page-heading">
        <h1 className="title">404 Request not allowed!!</h1>
      </div>
    );
  }
}

export default ErrorPage;
