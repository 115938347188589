import React from "react";

import "./../styles/index.css";
class Home extends React.Component {
  render() {
    return (
      <div className="page-heading">
        <h1 className="title">A model-based Digital Twin for power systems containing Distributed Energy Resources (DER)</h1>
        <p>This Digital Twin (DT) for power systems containing Distributed Energy Resources (DER) 
        enables running power system simulations and evaluate system level scenarios, 
        as well as interfacing with applications that produce forecasting using AI models.
        The simulations are performed with the open-source real-time simulator <a href="https://www.fein-aachen.org/en/projects/dpsim/">DPsim</a>.
        The underlying power system models of the DT are based on real world networks from the pilots within the I-NERGY project.
        </p>
        <h3 className="subheading">Contact</h3>
        <p>For more information, please contact <a href="mailto:leonardo.carreras@eonerc.rwth-aachen.de">Leonardo Carreras</a> or <a href="mailto:post-acs@eonerc.rwth-aachen.de">Institute ACS</a>.</p>
        <h3 className="subheading">Acknowledgements</h3>
        <p>This software was developed by the Institute for Automation of Complex Power Systems, EONERC, 
          RWTH Aachen University as part of the <a href="https://i-nergy.eu/">I-NERGY project</a>.</p>
        <img
          style={{ height: 90 }}
          src='/images/inergy-logo.png'
          alt="I-NERGY Logo"
        />
        <img
          style={{ height: 90 }}
          src='/images/eu_flag.svg'
          alt="EU Flag"
        />
        <p>The I-NERGY Project has received funding from the European Union's Horizon 2020 Research and Innovation programme under grant agreement No. 101016508</p>
        <p></p>
      </div>
    );
  }
}

export default Home;
